import { useEffect } from 'react';

import { Center, Loader } from '@mantine/core';
import { useSearchParams } from 'next/navigation';

import { AuthorizeLink } from '@/components/Ui/Elements/AuthorizeLink/AuthorizeLink';
import { useAuthorizeRouter } from '@/features/auth/hooks/useAuthorizeRouter';

/**
 * MEMO middleware内では認証コールバック後のrequestで成功しても認証済みとならないので、ログイン
 * ログアウトに関してのみクライアントサイドで処理する
 **/
const Redirect = () => {
  const params = useSearchParams();
  const callback = params.get('callback');
  const router = useAuthorizeRouter();
  useEffect(() => {
    if (callback !== 'true') {
      router.push({
        pathname: '/login',
      });
    }
  }, []);
  return (
    //TODO 直リンアクセス時にこのページで固まってしまうバグの対策
    // Use the value of authStatus to decide which page to render
    <>
      <Center h="100vh" w="100vw">
        <Loader m="auto" size="xl" variant="dots" />
        <AuthorizeLink
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
          pathname="/login"
        >
          ログイン画面
        </AuthorizeLink>
      </Center>
    </>
  );
};
export default Redirect;
